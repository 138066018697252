* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-item {
    display: block;
}

.header-dropdown button {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none;
}

.error {
    padding: 10px;
    margin-top: 10px;
    color: #ba3939;
    background: #ffe0e0;
    border: 1px solid #a33a3a;
    margin-bottom: 10px;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, .3) !important;
}

.blocked {
    pointer-events: none;
}

.ml-15 {
    margin-left: 15px;
}

.success {
    border: 1px solid;
    margin: 10px 0px;
    padding: 15px 10px 15px 50px;
    background-repeat: no-repeat;
    background-position: 10px center;
    color: #4F8A10;
    background-color: #DFF2BF;
}

#nav {
    background-color: black;
}

.navnav {
    display: flex;
}

.navbar {
    border-radius: 0 !important;
}

.navbar-header {
    width: 100%;
}

.navbar-header {
    width: 100% !important;
}

.navbar-no-auth-wrapper {
    display: flex;
    width: 100%;
}

.login-header-btn {
    float: right;
    margin-right: 15px !important;
    padding-top: 4px;
}

.padding-0 {
    padding: 0 !important;
}

.material-icons {
    display: block !important;
}

.flex {
    display: flex !important;
    align-items: center;
}

.mt-15 {
    margin-top: 15px;
}

.color-blue {
    color: #0c6db8;
    cursor: pointer;
}

.color-red {
    color: #b80c0c;
    cursor: pointer;
}

.navbar-header {
    box-sizing: border-box;
}

.company__image {
    width: 200px;
}

.drumGif {
    margin-left: 25%;
    margin-top: 25vh;
    width: 50%;
    height: 50vh;
}

.listContainerWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
}

.navbar-inverse .navbar-nav > li > a {
    color: #ffffff !important;
}

.warn {
    text-align: center;
    background-color: #FFF3CD;
    border-radius: 3px;
    color: #867b51;
}

.header-dropdown button {
    box-shadow: none !important;
}

.wrap > .container {
    padding: 0 !important;
}

.logoutHeader {
    color: #d54242;
    cursor: pointer;
    float: right;
    margin-top: 6px;
}

.dropdown-item-one {
    color: #ffffff !important;
}

.dropdown a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
}

@media (max-width: 560px) {
    .logoutHeader {
        float: left;
    }
}

.winner_wrapper {
    height: 100px;
    position: absolute;
    top: 50%;
    left: 30%;
    width: 40%;
    margin-top: -50px;
    align-items: center;
    text-align: center;
}

.winner-container {
    background-color: #1D1F20;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.winner {
    text-shadow: 0 0 10px rgb(255 150 50);
    color: #ffffff;
    font-size: 2.5em;
}

.winner_button-wrapper {
    display: flex;
    justify-content: space-around;
    position: relative;
}

.winner::after,
.winner::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    animation: quick-glow 1.25s 0.3s infinite linear;
    background-color: #dc2e00;
}

.winner::after {
    top: -10px;
}

.winner::before {
    bottom: 40px;
}

.winner_button {
    background-color: #dc2e00;
    height: 40px;
    width: 170px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgb(255 150 50);
    cursor: pointer;
}

.winner_button:hover {
    box-shadow: 0 0 10px rgb(255 150 50);
}

@keyframes quick-glow {
    15% {
        z-index: 99;
        background: #FF9529;
        box-shadow: 0 0 10px 2px #FF9529;
    }
}

.ticket-generate-form .ticket-radio {
    padding-left: 0;
}

.ticket-generate-form .ticket-radio label {
    padding-left: 1.5em;
}

.winnerTable {
    background-color: #1D1F20;
    color: #ffffff !important;
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    text-shadow: 0 0 10px rgb(255 150 50);
}

.winnerTable th {
    color: #ffffff !important;
    padding: 10px !important;
}

.winnerTable td {
    color: #ffffff !important;
}

.winnerTable_head {
    background-color: #FF9529 !important;
    box-shadow: 0 0 10px 2px #FF9529;
}

.allPrize-container {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #1D1F20;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
    text-shadow: 0 0 10px rgb(255 150 50);
    color: #ffffff;
    font-size: 2.2em;
    display: flex;
    align-items: center;
    height: 100vh;
}

.allPrize {
    width: 100%;
    position: relative;
}

.allPrize::after,
.allPrize::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    animation: quick-glow 1.25s 0.3s infinite linear;
    background-color: #dc2e00;
}

.allPrize::after {
    top: -10px;
}

.allPrize::before {
    bottom: -40px;
}

.pizeWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin-left: 20%;
    margin-top: 20px;
}

@keyframes quick-glow {
    15% {
        z-index: 99;
        background: #FF9529;
        box-shadow: 0 0 10px 2px #FF9529;
    }
}

.createWinnerButton {
}

.createWinnerButton::before, .createWinnerButton:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    animation: quick-glow 1.25s 0.3s infinite linear;
    background-color: #dc2e00;
}

.winner_button {
    background-color: #dc2e00;
    height: 40px;
    width: 170px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 10px;
    text-align: center;
    padding-top: 9px;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgb(255 150 50);
    cursor: pointer;
}

.winner_button-extend {
    width: 250px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    height: 50px;
    font-size: 16px;
    outline: none;
}

.winner_button:hover {
    box-shadow: 0 0 10px rgb(255 150 50);
}

.prizeWinner {
    height: 40px;
    /*width: 170px; */
    color: #ffffff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: .7em;
}

.mt-30 {
    margin-top: 80px;
}

.h150 {
    height: 180px;
}

.prize {
    font-size: 1.4em;
}

@keyframes openWidth {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

.gif-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1D1F20;
    width: 100%;
    height: 100%;
}

.inline {
    display: inline-block;
}

.w100 {
    width: 100%;
}

.qr-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.qr {
  width: 25%;
    display: inline-block;
    text-align: center;
    height: 200px;
    border-collapse: initial;
    border: 1px solid black !important;
    object-fit: cover;
    object-position: top;
}

@media print {
  .qr:nth-child(4n) {
    page-break-after: always!important;
  }
}

.qr img {
    height: 82%;
}

.qr-text {
    margin-top: -10px;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 600;
}

.manager-table {
    position: absolute;
    left: 5%;
}
