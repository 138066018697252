.drum_container {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    margin: 0;
    background-color: #1D1F20;
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
    overflow: hidden;
    padding: 30px 0 0 30px;
    text-align: center;
}

/*-------- COUNTDOWN STARTS ------- */
#countdown {
    position: relative;
    margin: auto;
    margin-top: 0px;
    height: 90px;
    width: 90px;
    text-align: center;
}

#countdown-number {
    color: #f44;
    display: inline-block;
    line-height: 120px;
    font-size: 75px;
    font-weight: bold;
    margin-top: 0;
    margin-left: 0;
    text-shadow: 0 0 10px rgba(255, 0, 0, 1);
}

/*-------- COUNTDOWN ENDS ------- */
/*-------- SPINNER FIRST STARTS ------- */
#division-loader-old {
    display: block;
    position: fixed;
    width: 160px;
    height: 160px;
    top: 57px;
    left: 55px;
}

#division-loader {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -108px 0 0 -108px;

}

#preloader {
    /*background-color: aquamarine;*/
    width: 150px;
    height: 150px;
    position: absolute;
    margin: -85px 0 0 -95px;
    left: 50%;
    top: 50%;

}

#preloader-old {
    position: fixed;
    margin: 0 auto;
    width: 170px;
    height: 146px;

    display: block;
}

#preloader2 {
    position: fixed;

    display: block;
}

#loader-old {
    display: block;
    position: fixed;

    width: 160px;
    height: 160px;

    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #d3601B;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

}

#loader {
    display: block;
    position: fixed;

    width: 240px;
    height: 240px;
    left: 50%;
    top: 50%;
    margin-left: -145px;
    margin-top: -145px;

    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #d3601B;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

}

#loader:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #e3400B;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;

}

#loader:after {
    content: "";
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #f3200B;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;

}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*-------- SPINNER FIRST ENDS ------- */

/*-------- WINNER TEXT STARTS ------- */

#winner-table {
    display: none;
}

.word {
    bottom: 0;
    color: #fff;
    font-size: 2.5em;
    height: 2.5em;
    left: 0;
    line-height: 2.5em;
    margin: auto;
    right: 0;
    position: absolute;
    text-shadow: 0 0 10px rgba(255, 150, 50, 1);
    top: 0
}

.word span {
    display: inline-block;
    transform: translateX(100%) scale(0.9);
    transition: transform 500ms;
}

.word .done {
    color: #fff;
    transform: translateX(0) scale(1);
}

/*-------- WINNER TEXT ENDS ------- */


/****** KEYFRAME ANIMATIONS ******/
@keyframes quick-glow {
    15% {
        z-index: 99;
        background: #FF9529;
        box-shadow: 0 0 10px 2px #FF9529;
    }
}

@keyframes glow {
    50% {
        z-index: 99;
        background: #FF2995;
        box-shadow: 0 0 10px 2px #FF2995;
    }
}

@-webkit-keyframes quick-glow {
    15% {
        z-index: 99;
        background: #FF2995;
        box-shadow: 0 0 10px 2px #FF2995;
    }
}

@-webkit-keyframes glow {
    50% {
        z-index: 99;
        background: #FF2995;
        box-shadow: 0 0 10px 2px #FF2995;
    }
}

/* -------- GLOW LINES END --------- */